import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Image1 from "../../../assets/images/Image1.jpg";
import Image2 from "../../../assets/images/Image2.jpg";
import Image3 from "../../../assets/images/Image3.jpg";
import Image4 from "../../../assets/images/Image4.jpg";
import Image5 from "../../../assets/images/Image5.jpg";
import { AuthServices } from "../../../services/Auth.services";
import { TipoImgGRI } from "../services/updateTemas.services";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";

export function Modal({ isOpen, setIsOpen, filterValue, updateCompany }) {
  const { register, handleSubmit } = useForm({});
  const [showSectorOptions, setShowSectorOptions] = useState(false);

  useEffect(() => {
    AuthServices.axiosInterceptors();
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  function OnSubmit(data) {
    TipoImgGRI(data);
    updateCompany(data);
    const time = setTimeout(() => setIsOpen(false), 3000);
    return () => clearTimeout(time);
  }

  return (
    <>
      <div>
        {isOpen ? (
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>

            <div className="flex items-center justify-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-3xl p-4 mx-auto  rounded-md">
                <section className="max-w-screen-lg md:w-auto leading-8 h-70 transform overflow-hidden rounded-2xl headlessui-dialog-panel bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <h6 className="text-btn-Previex">
                    El Reporte que se va a generar es:
                  </h6>
                  <div as="div" className="mt-4 w-full overflow-y-auto">
                    <form onSubmit={handleSubmit(OnSubmit)}>
                      <div className="flex flex-wrap">
                        <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                          <h6 className="text-btn-Previex text-gray-400 option-GRI">
                            1. De Conformidad con los Estándares GRI
                          </h6>
                          <input
                            required
                            type="radio"
                            name="GRI"
                            value="1"
                            onClick={() => {
                              filterValue("1", undefined);
                              sessionStorage.setItem("tipoReporte", "1");
                              setShowSectorOptions(false);
                            }}
                            className="cursor-pointer"
                            {...register("NTipo")}
                          />
                        </div>

                        <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                          <h6 className="text-btn-Previex text-gray-400 option-GRI">
                            2. GRI Referenciado
                          </h6>
                          <input
                            required
                            type="radio"
                            value="2"
                            onClick={() => {
                              filterValue("2", undefined);
                              sessionStorage.setItem("tipoReporte", "2");
                              setShowSectorOptions(true);
                            }}
                            className="cursor-pointer"
                            {...register("NTipo")}
                          />
                        </div>
                      </div>

                      {showSectorOptions && (
                        <div className="mt-4">
                          <h6 className="text-btn-Previex">
                            ¿Desea utilizar un estándar sectorial?​
                          </h6>
                          <div className="flex flex-wrap mt-2">
                            <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                              <h6 className="text-btn-Previex text-gray-400 option-GRI">
                                Petróleo y Gas
                              </h6>
                              <input
                                type="radio"
                                name="sector"
                                value="Petróleo y Gas"
                                className="cursor-pointer"
                                onClick={() => {
                                  filterValue("2", "Petróleo y gas");
                                  setShowSectorOptions(true);
                                }}
                              />
                            </div>
                            <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                              <h6 className="text-btn-Previex text-gray-400 option-GRI">
                                Carbón
                              </h6>
                              <input
                                name="sector"
                                type="radio"
                                value="Carbón"
                                className="cursor-pointer"
                                onClick={() => {
                                  filterValue("2", "Carbón");
                                  setShowSectorOptions(true);
                                }}
                              />
                            </div>
                            <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                              <h6 className="text-btn-Previex text-gray-400 option-GRI">
                                Agricultura, acuicultura y pesca
                              </h6>
                              <input
                                type="radio"
                                name="sector"
                                value="Agricultura, acuicultura y pesca"
                                className="cursor-pointer"
                                onClick={() => {
                                  filterValue("2", "Agricultura, acuicultura y pesca");
                                  setShowSectorOptions(true);
                                }}
                              />
                            </div>
                            <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                              <h6 className="text-btn-Previex text-gray-400 option-GRI">
                                Minería
                              </h6>
                              <input
                                type="radio"
                                name="sector"
                                value="Minería"
                                className="cursor-pointer"
                                onClick={() => {
                                  filterValue("2", "Minería");
                                  setShowSectorOptions(true);
                                }}
                              />
                            </div>
                            <div className="flex items-center justify-end flex-row-reverse gap-2 p-2">
                              <h6 className="text-btn-Previex text-gray-400 option-GRI">
                                No Aplica
                              </h6>
                              <input
                                type="radio"
                                name="sector"
                                className="cursor-pointer"
                                value="No"
                              />
                            </div>
                            </div>
                          </div>
                      )}

                      <h6 className="text-btn-Previex mt-4">
                        Seleccione una portada para su informe:
                      </h6>
                      <section className="flex container-imagesGRI pt-4">
                        <div className="item-imagesGRI">
                          <img
                            className="imagesGRI"
                            alt="image1"
                            src={Image1}
                          />
                          <input
                            type="radio"
                            className="absolute top-0 right-0 m-2 cursor-pointer"
                            value="1"
                            {...register("NImagen")}
                            required
                          />
                        </div>
                        <div className="item-imagesGRI">
                          <img
                            className="imagesGRI"
                            alt="image2"
                            src={Image2}
                          />
                          <input
                            type="radio"
                            className="absolute top-0 right-0 m-2 cursor-pointer"
                            value="2"
                            {...register("NImagen")}
                            required
                          />
                        </div>
                        <div className="item-imagesGRI">
                          <img className="imagesGRI" src={Image3} />
                          <input
                            type="radio"
                            className="absolute top-0 right-0 m-2 cursor-pointer"
                            value="3"
                            {...register("NImagen")}
                            required
                          />
                        </div>
                        <div className="item-imagesGRI">
                          <img className="imagesGRI" src={Image4} />
                          <input
                            type="radio"
                            className="absolute top-0 right-0 m-2 cursor-pointer"
                            value="4"
                            {...register("NImagen")}
                            required
                          />
                        </div>
                        <div className="item-imagesGRI">
                          <img className="imagesGRI" src={Image5} />
                          <input
                            type="radio"
                            className="absolute top-0 right-0 m-2 cursor-pointer"
                            value="5"
                            {...register("NImagen")}
                            required
                          />
                        </div>
                      </section>

                      <div className="flex justify-center">
                        <input
                          type="submit"
                          value="Guardar cambios"
                          className="boxshadow cursor-pointer  btn-generarReporte mt-4"
                        />
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
