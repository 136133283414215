import { useState } from "react";
import { Footer } from "../../layouts/Footer";
import { HeaderGeneric } from "../../components/common/HeaderGeneric";
import { useForm } from "react-hook-form";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { IconContext } from "react-icons";
import { newPassword } from "./service/newPassword";
import { ErrorMessage } from "@hookform/error-message";
import { TiWarningOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { Back } from "../../components/common/Back";

export const InsertNewPassword = () => {
  const [openEye, setOpenEye] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    setOpenEye(!openEye);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const onFormSubmit = (data) => {
    data.email = sessionStorage.getItem("email");
    newPassword(data);
    const time = setTimeout(() => navigate("/app"), 1000);
    return () => clearTimeout(time);
  };

  return (
    <>
      <HeaderGeneric />
      <main className="items-center justify-center mb-9">
        <div className="container mx-auto p-2 w-80 shadow-2xl rounded-lg">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="bg-white w-95 p-8  rounded-lg">
            <div className="flex justify-center items-center">
      {window.location.href==="https://reportegri.com/" ? '' : <NavLink to={"/home"}>
        <Back
        className={'bracket'}
        height={'25px'}
        width={'25px'}
        fill={'#1158a6'}
        />
       
        </NavLink> }
        <h3 className="p-2 text-center primary-title reset-password">
                Restablecer contraseña
              </h3>
        </div>
              
              <label
                htmlFor="code"
                className="block text-sm font-semibold py-2.5  text-start secondary-title"
              >
                Codigo
              </label>
              <input
                type="text"
                required
                name="code"
                autoComplete="username email"
                className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.code &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("code", {
                  required: "Éste campo es obligatorio.",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Ingrese un código válido",
                  },
                })}
                placeholder="Codigo"
              />
              <ErrorMessage
                errors={errors}
                name="code"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />
              <label
                htmlFor="password"
                className="block text-sm py-2.5  text-start font-semibold  secondary-title"
              >
                Nueva Contraseña
              </label>
              <div className="mb-2 relative">
                <input
                  {...register("password", {
                    required: "Éste campo es obligatorio",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d]|[^ ]){8,15}$/,
                      message:
                        "Min 8 Max 15 caracteres, una letra Min,May, un dígito y no espacios.",
                    },
                  })}
                  type={openEye === false ? "password" : "text"}
                  autoComplete="current-password"
                  required
                  className={`block w-full px-4 py-2 bg-white border bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs ${
                    errors.password &&
                    "focus:border-red-500 focus:ring-red-500 border-red-500"
                  }`}
                  placeholder="Contraseña"
                />
                <IconContext.Provider value={{ color: "grey", size: "17px" }}>
                  <div className="text-1xl absolute top-2 right-2">
                    {openEye === false ? (
                      <AiFillEye onClick={toggle} />
                    ) : (
                      <AiFillEyeInvisible onClick={toggle} />
                    )}
                  </div>
                </IconContext.Provider>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <div className="flex justify-start py-1 gap-0.5">
                            {" "}
                            <TiWarningOutline className="text-red-500" />
                            <small
                              className="text-xs text-start text-red-500 message"
                              key={type}
                            >
                              {message}
                            </small>
                          </div>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="mt-5 text-center">
                <button
                  type="submit"
                  className="boxshadow px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-color-btn rounded-md hover:bg-color-btn focus:outline-none font-semibold focus:bg-color-btn"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </form>
        </div>
        <Toaster position="top-right" />
      </main>
      <Footer />
    </>
  );
};
