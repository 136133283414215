import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export function ModalSectorial({ showModalSectorial, setShowModalSectorial, company }) {

  const {
    register,
    reset,
    handleSubmit,
    watch,
  } = useForm({ defaultValues: { isValid: "" } });

  function onSubmit(e) {
    reset();
  }

  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <>
      {showModalSectorial ? (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-full px-4 py-8">
            <div className="relative w-full max-w-3xl p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3 sm:flex justify-center"></div>

              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                <div>
                  <>
                    <section className="max-w-screen-sm mx-auto">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="">
                          <div className="">
                            <h3 className="pb-8 text-black text-center font-bold text-xl text-title">
                              Reporte de conformidad sectorial
                            </h3>
                            <p className="text-black font-normal text-md text-parrafo">
                            Al ser un reporte de conformidad se debe aplicar el estándar sectorial correspondiente.
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-center flex-wrap pt-8 gap-2">
                          <button
                            onClick={() => setShowModalSectorial(false)}
                            type="button"
                            className="boxshadow cursor-pointer  btn-generarReporte mt-4"
                          >
                            Aceptar
                          </button>
                        </div>
                      </form>
                    </section>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
