import { React, useState, useEffect, useRef } from "react";
import { baseURL } from "../../../services/baseURL";
import axios from "axios";
import { ModalTemasNoGRI } from "./ModalTemasNoGRI";
import { Disclosure } from "@headlessui/react";
import { BtnViewPreview } from "./BtnViewPreview";
import { BtnViewHistory } from "./BtnViewHistory";
import { clone, cloneDeep } from "lodash";
import { AuthServices } from "../../../services/Auth.services";
import { UpdateTemasNoGRI } from "./UpdateTemasNoGRI";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImCalendar } from "react-icons/im";
import { IconContext } from "react-icons";
import { Modal } from "./Modal";
import {
  GetTemasNoGRI,
  GetValueUser,
  TipoImgGRI,
} from "../services/updateTemas.services";
import {
  thematicContents,
  ThematicContentsNoGRI,
  ThematicContentsPlantillas,
} from "../services/globalVariables";
import es from "date-fns/locale/es";
import { Footer } from "../../../layouts/Footer.js";
import { ModalSectorial } from "./ModalSectorial.js";
registerLocale("es", es);

export const NavTabs = () => {
  const [toggleState, setToggleState] = useState(1);
  const [TemaNoGRI, setTemaNoGRI] = useState([]);
  const [objectGRI, setObjectGRI] = useState([]);
  const [Data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModalSectorial, setShowModalSectorial] = useState(false);
  const [check, setIsChecked] = useState(false);
  const [disable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [variableReferenciadoSector, setVariableReferenciadoSector] =
    useState(false);
  const [messageSectorial, setMessageSectorial] = useState(false);
  const [company, setCompany] = useState("");
  const [arraySectorial, setArraySectorial] = useState([]);
  const [typeSector, setTypeSector] = useState(false);
  const [nuevoSectorial, setNuevoSectorial] = useState(false);
  const [history, setHistory] = useState([]);
  const [temasSectoriales, setTemasSectoriales] = useState([]);
  const [contenidosObject, setContenidosObject] = useState({});
  const buttonRefs = useRef([]);
  const openedRef = useRef(null);
  const [diccionario, setDiccionario] = useState([
    {
      topic: "GRI 11: Sector Petróleo y gas",
      content: [
        { NContenido: "Tema 11.1 Emisiones GEI" },
        { NContenido: "Tema 11.2 Adaptación al clima, resiliencia y transición" },
        { NContenido: "Tema 11.3 Emisiones al aire" },
        { NContenido: "Tema 11.4 Biodiversidad" },
        { NContenido: "Tema 11.5 Residuos" },
        { NContenido: "Tema 11.6 Agua y efluentes" },
        { NContenido: "Tema 11.7 Cierre y rehabilitación" },
        { NContenido: "Tema 11.8 Integridad de los activos y gestión de incidentes críticos" },
        { NContenido: "Tema 11.9 Salud y Seguridad en el trabajo" },
        { NContenido: "Tema 11.10 Prácticas de empleo" },
        { NContenido: "Tema 11.11 No discriminación e igualdad de oportunidades" },
        { NContenido: "Tema 11.12 Trabajo forzoso y esclavitud moderna" },
        { NContenido: "Tema 11.13 Libertad de asociación y negociación colectiva" },
        { NContenido: "Tema 11.14 Impactos económicos" },
        { NContenido: "Tema 11.15 Comunidades locales" },
        { NContenido: "Tema 11.16 Derechos sobre la tierra y los recursos" },
        { NContenido: "Tema 11.17 Derechos de los pueblos indígenas " },
        { NContenido: "Tema 11.18 Conflictos y seguridad" },
        { NContenido: "Tema 11.19 Competencia desleal" },
        { NContenido: "Tema 11.20 Anticorrupción" },
        { NContenido: "Tema 11.21 Pagos a los gobiernos" },
        { NContenido: "Tema 11.22 Política pública" },
      ],
      isChecked: 0,
    },
    {
      topic: "GRI 12: Sector Carbón",
      content: [
        { NContenido: "Tema 12.1 Emisiones GEI" },
        { NContenido: "Tema 12.2 Adaptación al clima, resiliencia y transición" },
        { NContenido: "Tema 12.3 Cierre y rehabilitación" },
        { NContenido: "Tema 12.4 Emisiones al aire" },
        { NContenido: "Tema 12.5 Biodiversidad" },
        { NContenido: "Tema 12.6 Residuos" },
        { NContenido: "Tema 12.7 Agua y efluentes" },
        { NContenido: "Tema 12.8 Impactos económicos" },
        { NContenido: "Tema 12.9 Comunidades locales" },
        { NContenido: "Tema 12.10 Derechos sobre la tierra y los recursos" },
        { NContenido: "Tema 12.11 Derechos de los pueblos indígenas" },
        { NContenido: "Tema 12.12 Conflictos y seguridad" },
        { NContenido: "Tema 12.13 Integridad de los activos y la gestión de incidentes críticos" },
        { NContenido: "Tema 12.14 Salud y seguridad en el trabajo" },
        { NContenido: "Tema 12.15 Prácticas de empleo" },
        { NContenido: "Tema 12.16 Trabajo infantil" },
        { NContenido: "Tema 12.17 Trabajo forzoso y esclavitud moderna" },
        { NContenido: "Tema 12.18 Libertad de asociación y negociación colectiva" },
        { NContenido: "Tema 12.19 No discriminación e igualdad de oportunidades" },
        { NContenido: "Tema 12.20 Anticorrupción" },
        { NContenido: "Tema 12.21 Pagos a los gobiernos" },
        { NContenido: "Tema 12.22 Política pública" },
      ],
      isChecked: 0,
    },
    {
      topic: "GRI 13: Agricultura, acuicultura y pesca",
      content: [
        { NContenido: "Tema 13.1 Emisiones" },
        { NContenido: "Tema 13.2 Adaptación al clima y resiliencia" },
        { NContenido: "Tema 13.3 Biodiversidad" },
        { NContenido: "Tema 13.4 Conversión de los ecosistemas naturales" },
        { NContenido: "Tema 13.5 Salud del suelo" },
        { NContenido: "Tema 13.6 Uso de pesticidas" },
        { NContenido: "Tema 13.7 Agua y efluentes" },
        { NContenido: "Tema 13.8 Residuos" },
        { NContenido: "Tema 13.9 Seguridad alimentaria" },
        { NContenido: "Tema 13.10 Inocuidad de los alimentos" },
        { NContenido: "Tema 13.11 Salud y bienestar animal" },
        { NContenido: "Tema 13.12 Comunidades locales" },
        { NContenido: "Tema 13.13 Derechos sobre la tierra y los recursos" },
        { NContenido: "Tema 13.14 Derechos sobre los pueblos indígenas" },
        { NContenido: "Tema 13.15 No discriminación e igualdad de oportunidades" },
        { NContenido: "Tema 13.16 Trabajo forzoso u obligatorio" },
        { NContenido: "Tema 13.17 Trabajo infantil" },
        { NContenido: "Tema 13.18 Libertad de asociación y negociación colectiva" },
        { NContenido: "Tema 13.19 Salud y seguridad en el trabajo" },
        { NContenido: "Tema 13.20 Prácticas de empleo" },
        { NContenido: "Tema 13.21 Ingresos y salarios vitales" },
        { NContenido: "Tema 13.22 Inclusión económica" },
        { NContenido: "Tema 13.23 Trazabilidad de la cadena de suministro" },
        { NContenido: "Tema 13.24 Política pública" },
        { NContenido: "Tema 13.25 Competencia desleal" },
        { NContenido: "Tema 13.26 Anticorrupción" },
      ],
      isChecked: 0,
    },
    {
      topic: "GRI 14: Minería",
      content: [
        { NContenido: "Tema 14.1 Emisiones de GEI" },
        { NContenido: "Tema 14.2 Adaptación al clima y resiliencia" },
        { NContenido: "Tema 14.3 Emisiones al aire" },
        { NContenido: "Tema 14.4 Biodiversidad" },
        { NContenido: "Tema 14.5 Residuos" },
        { NContenido: "Tema 14.6 Relaves" },
        { NContenido: "Tema 14.7 Agua y efluentes" },
        { NContenido: "Tema 14.8 Cierre y rehabilitación" },
        { NContenido: "Tema 14.9 Impactos económicos" },
        { NContenido: "Tema 14.10 Comunidades locales" },
        { NContenido: "Tema 14.11 Derechos de los pueblos indígenas" },
        { NContenido: "Tema 14.12 Derechos sobre la tierra y los recursos" },
        { NContenido: "Tema 14.13 Minería artesanal y a pequeña escala" },
        { NContenido: "Tema 14.14 Prácticas en materia de seguridad" },
        { NContenido: "Tema 14.15 Gestión de incidentes críticos" },
        { NContenido: "Tema 14.16 Salud y seguridad en el trabajo" },
        { NContenido: "Tema 14.17 Prácticas de empleo" },
        { NContenido: "Tema 14.18 Trabajo infantil" },
        { NContenido: "Tema 14.19 Trabajo forzoso y esclavitud moderna" },
        { NContenido: "Tema 14.20 Libertad de asociación y negociación colectiva" },
        { NContenido: "Tema 14.21 No discriminación e igualdad de oportunidades" },
        { NContenido: "Tema 14.22 Anticorrupción" },
        { NContenido: "Tema 14.23 Pagos a los gobiernos" },
        { NContenido: "Tema 14.24 Política pública" },
        { NContenido: "Tema 14.25 Áreas de conflicto y de alto riesgo" },
      ],
      isChecked: 0,
    },
  ]);

  function openModal(objets, companyInfo) {
    if (!window.sessionStorage.getItem("mostrarModal")) {
      setIsOpen(true);
    } else {
      filterValue(sessionStorage.getItem("tipoReporte"), objets, companyInfo);
    }
  }

  const handleChangeSectorialNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  
    let temaPrefixes = [];
  
    // Realizamos una copia de diccionario para evitar mutaciones directas
    const updatedDiccionario = diccionario.map((el) => {
      if (el.topic === name) {
        // Copiamos el contenido de cada objeto para evitar mutaciones directas
        const updatedContent = el.content.map((ele) => {
          // Creamos una nueva referencia para cada elemento en el contenido
          const newEle = { ...ele, isChecked: checked };
  
          // Revisamos si el contenido tiene un prefijo que aún no hemos agregado
          const match = newEle.NContenido.match(/(\d+\.\d+)/);
          if (match && !temaPrefixes.includes(match[0])) {
            temaPrefixes.push(match[0]);
          }
  
          return newEle; // Retornamos el nuevo objeto
        });
  
        // Retornamos el objeto actualizado con el nuevo contenido
        return { ...el, isChecked: checked, content: updatedContent };
      }
      return el; // Si el topic no coincide, retornamos el objeto sin cambios
    });
  
    console.log(temaPrefixes);
  
    // Actualizamos el estado de manera inmutable
    setData((prevData) => {
      const newData = { ...prevData };
  
      // Si se encontraron prefijos, actualizamos los elementos relacionados
      if (temaPrefixes.length > 0) {
        Object.keys(newData).forEach((key) => {
          const sectionArray = newData[key];
          if (Array.isArray(sectionArray)) {
            sectionArray.forEach((section) => {
              section.content.forEach((item) => {
                if (item.contenido) {
                  temaPrefixes.forEach((prefix) => {
                    if (
                      item.contenido.some((contenidoItem) =>
                        contenidoItem.startsWith("Tema " + prefix + ".")
                      )
                    ) {
                      item.isChecked = checked;
                    }
                  });
                }
              });
            });
          }
        });
      }
  
      return newData;
    });
  
    // Actualizamos diccionario con el resultado final
    setDiccionario(updatedDiccionario);
  };

  const handleChangeSectorialNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    diccionario.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NContenido === name) {
          ele.isChecked = checked;
        }
      });
    });

    // Extraemos el prefijo del tema marcado (por ejemplo, "12.1" de "Tema 12.1 Emisiones de GEI")
    const temaPrefix = name.match(/(\d+\.\d+)/)?.[0];

    if (!temaPrefix) return; // Si no podemos extraer el prefijo, no hacemos nada

    Object.keys(newData).forEach((key) => {
      const sectionArray = newData[key];
      console.log(sectionArray);
      sectionArray.forEach((section) => {
        section.content.forEach((item) => {
          // Verificamos si algún string en 'item.contenido' comienza con el prefijo extraído
          if (
            item.contenido &&
            item.contenido.some((contenidoItem) =>
              contenidoItem.startsWith("Tema " + temaPrefix + ".")
            )
          ) {
            item.isChecked = checked; // Marcamos o desmarcamos el item según corresponda
          }
        });
      });
    });

    setData(newData);
  };

  const filterValue = (value, value2, dataFilter = Data, companyInfo = company) => {
    // Copia profunda del objeto para asegurar la inmutabilidad
    const newData = structuredClone(dataFilter); // Usa `cloneDeep` si prefieres lodash
  
    let arrayIsSectorial = [];
    let arraySectorial = [];
  
    newData.todos = newData.todos?.map((el) => {
      const updatedContent = el.content.map((ele) => {
        if (value === "1") {
          if (ele.NGri === "1") {
            setIsChecked(true);
            setIsDisable(true);
            ele = { ...ele, isChecked: true, isDisable: true };
            setTypeSector(true);
            setToggleState(1);
            setVariableReferenciadoSector(false);
          } else {
            ele = { ...ele, isChecked: false, isDisable: false };
          }
  
          if (ele.isSectorial === "1" && ele.NSectorTema === companyInfo.NSector) {
            setShowModalSectorial(true);
            setMessageSectorial(true);
            if (ele.NRelacionado === "0") {
              arraySectorial.push(ele);
            }
            if (!arrayIsSectorial[ele.NRelacionado]) {
              arrayIsSectorial[ele.NRelacionado] = [];
            }
            arrayIsSectorial[ele.NRelacionado].push({
              sectorial: ele.isSectorial,
              contenido: ele.NContenido,
              relacionado: ele.NRelacionado,
              texto: ele.NTexto,
            });
          }
        }
  
        if (value === "2") {
          if (ele.NRef === "1") {
            ele = { ...ele, isChecked: true, isDisable: true };
          } else {
            ele = { ...ele, isChecked: false, isDisable: false };
          }
  
          if (ele.isSectorial === "1" && ele.NSectorTema === value2) {
            const filteredDiccionario = diccionario.filter((item) => {
              if (value2 === "Petróleo y gas") {
                return item.topic === "GRI 11: Sector Petróleo y gas";
              } else if (value2 === "Carbón") {
                return item.topic === "GRI 12: Sector Carbón";
              } else if (value2 === "Agricultura, acuicultura y pesca") {
                return item.topic === "GRI 13: Agricultura, acuicultura y pesca";
              } else if (value2 == "Minería") {
                return item.topic == "GRI 14: Minería";
              }
              return false; // Puedes agregar más condiciones aquí si necesitas más filtrados
            });
            setDiccionario(filteredDiccionario);
            setNuevoSectorial(true);
            setToggleState(5);
            // setMessageSectorial(true);
            if (ele.NRelacionado === "0") {
              arraySectorial.push(ele);
            }
            if (!arrayIsSectorial[ele.NRelacionado]) {
              arrayIsSectorial[ele.NRelacionado] = [];
            }
            arrayIsSectorial[ele.NRelacionado].push({
              sectorial: ele.isSectorial,
              contenido: ele.NContenido,
              relacionado: ele.NRelacionado,
              texto: ele.NTexto,
            });
            setVariableReferenciadoSector(true);
          }
        }
  
        return ele;
      });
  
      return { ...el, content: updatedContent };
    });
  
    const sections = ["todos", "ambiental", "social", "economico"];
    
    sections.forEach((section) => {
      newData[section]?.forEach((c) => {
        c.content = c.content.map((d) => ({
          ...d,
          contenido: [],
          texto: d.texto || [],
        }));
      });
  
      arrayIsSectorial.forEach((a) => {
        newData[section]?.forEach((c) => {
          c.content = c.content.map((d) => {
            a.forEach((e) => {
              if (e.relacionado === d.id) {
                d = {
                  ...d,
                  contenido: [...d.contenido, e.contenido],
                  texto: [...d.texto, e.texto],
                };
                if (value !== "2") {
                d.isChecked = true;
                d.isDisable = true;
                }
              }
            });
            return d;
          });
        });
      });
    });

    setArraySectorial(arraySectorial);
    setData(newData);
    console.log(newData);
  };
  

  const handleChangeContent = (e) => {
    const { name, checked, value } = e.target;

    const newData = cloneDeep(Data);

    newData.todos.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NContenido === name) {
          ele.isChecked = checked;
          return;
        }

        if (value === "1") {
          if (ele.NGri === "1") {
            ele.isChecked = checked;
          }
        }

        if (value === "2") {
          if (ele.NRef === "1") {
            ele.isChecked = checked;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeContent2 = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    if (check === false && disable === false) {
      setIsChecked(true);
    }

    if (check === true && disable === false) {
      setIsChecked(false);
    }

    newData.todos.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name && ele.isDisable === false) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  const clickRecent = (index) => {
    const clickedButton = buttonRefs.current[index];
    if (clickedButton === openedRef.current) {
      openedRef.current = null;
      return;
    }
    if (Boolean(openedRef.current?.getAttribute("data-value"))) {
      openedRef.current?.click();
    }
    openedRef.current = clickedButton;
  };

  const repeatHistory = (ids) => {
    const newData = cloneDeep(Data);

    Object.entries(newData.todos).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });

    Object.entries(newData.economico).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });
    Object.entries(newData.ambiental).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });
    Object.entries(newData.social).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });

    setData(newData);
  };

  const handleChangeEconomyNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    Object.entries(newData.economico).forEach(([key, value]) => {
      let checkestandar = false;

      value.content.forEach((a, index) => {
        if (a.NContenido === name) {
          a.isChecked = checked;
          if (value.topic === a.NTema) {
            value.content.forEach((el) => {
              if (el.isChecked && el.NContenido.indexOf("Estándar") === -1) {
                checkestandar = true;
              }
            });
            value.content[0].isChecked = checkestandar;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeEconomyNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);
    newData.economico.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  const handleChangeAmbientalNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    Object.entries(newData.ambiental).forEach(([key, value]) => {
      let checkestandar = false;

      value.content.forEach((a, index) => {
        if (a.NContenido === name) {
          a.isChecked = checked;
          if (value.topic === a.NTema) {
            value.content.forEach((el) => {
              if (el.isChecked && el.NContenido.indexOf("Estándar") === -1) {
                checkestandar = true;
              }
            });
            value.content[0].isChecked = checkestandar;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeSocialNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    Object.entries(newData.social).forEach(([key, value]) => {
      let checkestandar = false;

      value.content.forEach((a, index) => {
        if (a.NContenido === name) {
          a.isChecked = checked;
          if (value.topic === a.NTema) {
            value.content.forEach((el) => {
              if (el.isChecked && el.NContenido.indexOf("Estándar") === -1) {
                checkestandar = true;
              }
            });
            value.content[0].isChecked = checkestandar;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeAmbientalesNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);
    newData.ambiental.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  const handleChangeSocialNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);
    newData.social.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  async function plantillas(companyInfo) {
    await axios
      .get(`${baseURL}/getcontenido`)
      .then((res) => {
        let data = res.data;

        let contenidoObject = {};

        data.forEach((el) => {
          contenidoObject[el.id] = el.NContenido;
        });

        setContenidosObject(contenidoObject);

        let thematicContentGlobal = {
          Social: "social",
          Ambiental: "ambiental",
          Económico: "economico",
          0: "todos",
        };

        thematicContents.forEach((el) => {
          let aux = [];
          const dataValueNTipo = cloneDeep(data);
          let renderNTipo = dataValueNTipo.filter((key) => key.NTipo === el);
          let uniqueValueNTema = [...new Set(renderNTipo.map((a) => a.NTema))];

          uniqueValueNTema.forEach((el2) => {
            let z = renderNTipo.filter((val) => val.NTema === el2);
            aux.push({ topic: el2, content: z });
          });
          ThematicContentsPlantillas[thematicContentGlobal[el]] = aux;
        });

        for (const key in ThematicContentsPlantillas) {
          if (Object.hasOwnProperty.call(ThematicContentsPlantillas, key)) {
            const element = ThematicContentsPlantillas[key];
            for (let index = 0; index < element.length; index++) {
              const element2 = element[index];
              element[index].content = element2.content.sort((a, b) => {
                return (
                  (a.NContenido.match(/-\d+/)
                    ? a.NContenido.match(/-\d+/)[0].substring(1)
                    : 0) -
                  (b.NContenido.match(/-\d+/)
                    ? b.NContenido.match(/-\d+/)[0].substring(1)
                    : 0)
                );
              });
            }

            ThematicContentsPlantillas[key] = element.sort((a, b) => {
              let factora =
                a.content[0].NContenido.indexOf("Tema") > -1 ? 1000 : 1;
              let factorb =
                b.content[0].NContenido.indexOf("Tema") > -1 ? 1000 : 1;
              return (
                a.topic.match(/\d+/)[0] / factora -
                b.topic.match(/\d+/)[0] / factorb
              );
            });
          }
        }
        setData(ThematicContentsPlantillas);
        setLoading(false);
        openModal(ThematicContentsPlantillas, companyInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const TemasNOGRI = (temaNOGRI) => {
    const objectsTemasNoGRI = { social: [], ambiental: [], economico: [] };
    thematicContents.forEach((el) => {
      let test = [];

      let renderNTipo = temaNOGRI.filter((key) => key.NTipo === el);
      let uniqueValueNTema = [...new Set(renderNTipo.map((a) => a.NTema))];

      uniqueValueNTema.forEach((el2) => {
        let z = renderNTipo.filter((val) => val.NTema === el2);
        test.push({ topic: el2, content: z });
      });
      objectsTemasNoGRI[ThematicContentsNoGRI[el]] = test;
      setTemaNoGRI(objectsTemasNoGRI);
    });
  };

  function updateCompany(data) {
    company.NImagen = data.NImagen;
    company.NTipo = data.NTipo;
    setCompany(company);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([GetValueUser(), GetTemasNoGRI()])
      .then(([valueCompany, temasNOGRI]) => {
        setCompany(valueCompany[0]);
        plantillas(valueCompany[0]);
        TemasNOGRI(temasNOGRI);
        setObjectGRI(temasNOGRI);
        getHistory();
      })
      .catch((er) => console.log(er));
    filterValue();
    AuthServices.axiosInterceptors();
  }, []);

  async function getHistory() {
    await axios
      .get(`${baseURL}/gethistorial`)
      .then((res) => {
        setHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleChangeEconomyNContenNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const TemasNoGRI = cloneDeep(TemaNoGRI);
    TemasNoGRI.economico.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NIndicador === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(TemasNoGRI);
  };

  const handleChangeEconomyNTemaNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const TemasNoGRI = cloneDeep(TemaNoGRI);
    TemasNoGRI.economico.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(TemasNoGRI);
  };

  const handleChangeAmbientalNTemaNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.ambiental.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const handleChangeAmbientalNContenNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.ambiental.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NIndicador === name) {
          ele.isChecked = checked;
        }
      });
    });

    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const handleChangeSocialNTemaNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.social.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const handleChangeSocialNContenNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.social.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NIndicador === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const onChangeCalendar = (Date) => {
    const [start, end] = Date;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className="">
        <div className="max-w-screen-lg mx-auto p-7 flex flex-wrap   justify-between container-date">
          <div>
            <label htmlFor="" className="labelcalendar">
              {" "}
              Fecha de Reporte
            </label>

            <div className="flex gap-2 pt-1.5">
              <div className="relative">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeCalendar}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  locale={"es"}
                  shouldCloseOnSelect={startDate && !endDate}
                  placeholderText="Fecha Inicial, Fecha Final"
                  className="custom-pickers"
                  dateFormat={"dd/MM/yyyy"}
                />
                <IconContext.Provider value={{ color: "grey", size: "19px" }}>
                  <div className="text-1xl absolute  icon-calendar-start-date">
                    <ImCalendar />
                  </div>
                </IconContext.Provider>
              </div>
            </div>
          </div>
          <div>
            <BtnViewHistory
              history={history}
              contenidos={contenidosObject}
              repeatHistory={repeatHistory}
            />
          </div>
          <div>
            <BtnViewPreview
              Data={Data}
              startDate={startDate}
              endDate={endDate}
              TemaNoGRI={TemaNoGRI}
              typesector={typeSector}
              messageSectorial={messageSectorial}
              arraySectorial={arraySectorial}
              company={company}
            />
          </div>
        </div>

        <section className="max-w-screen-lg contain-tab flex mx-auto p-7 gap-14">
          {variableReferenciadoSector && (
            <div>
              <button
                className={
                  toggleState === 5
                    ? "tabs active-tabs"
                    : "tabs transform transition duration-500 hover:text-white"
                }
                onClick={() => setToggleState(5)}
              >
                Estándares Sectoriales
              </button>
            </div>
          )}
          <button
            className={
              toggleState === 1
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(1)}
          >
            Estándares Universales
          </button>
          <button
            className={
              toggleState === 2
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(2)}
          >
            Estándares Económicos
          </button>
          <button
            className={
              toggleState === 3
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(3)}
          >
            Estándares Ambientales
          </button>
          <button
            className={
              toggleState === 4
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(4)}
          >
            Estándares Sociales
          </button>
        </section>
        <div className="flex justify-center animate-pulse">
          {loading && (
            <div>
              <div className="flex items-center justify-center space-x-2 h-40 mt-16 animate-bounce">
                <div className="w-14 h-14  bg-sky-600 flex justify-center items-center text-white md:font-bold text-title  rounded-full">
                  G
                </div>
                <div className="w-14 h-14  bg-sky-600 flex justify-center items-center text-white md:font-bold text-title rounded-full">
                  R
                </div>
                <div className="w-14 h-14  bg-sky-600 flex justify-center items-center text-white md:font-bold text-title rounded-full">
                  I
                </div>
              </div>
            </div>
          )}
        </div>

        {!loading && (
          <div className="container-accordion">
            <div className="content-tabs">
              <div
                className={
                  toggleState === 5 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className="bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {diccionario?.map((el, index) => (
                      <Disclosure key={el.name}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="btn-accordion flex justify-between px-5 py-2 text-sm font-medium text-left accordion-head rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeSectorialNTema}
                                    name={el.topic}
                                    checked={el.isChecked}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content?.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    onChange={handleChangeSectorialNConten}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    {ele.NContenido}.
                                    <br />
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="content-tabs">
              <div
                className={
                  toggleState === 1 ? "content active-content" : "content"
                }
              >
                <div>
                  {" "}
                  <div className="sectorial">
                    {messageSectorial === true
                      ? `Estándar sectorial GRI aplicable: ${company.NSector}`
                      : ""}
                  </div>
                </div>
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.todos?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div
                              className={`mb-2 rounded-md border ${
                                el.content[0].isSectorial === "1"
                                  ? "sector"
                                  : ""
                              }`}
                            >
                              <Disclosure.Button
                                as="div"
                                className="transform cursor-pointer transition duration-500  btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div
                                  className={`flex text-start gap-2  accordion items-center accordion${index}`}
                                >
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeContent2}
                                    name={el.topic}
                                    checked={check}
                                    disabled={disable}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="transform cursor-pointer transition duration-500  accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black transform cursor-pointer transition duration-500`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    id={ele.id}
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    disabled={ele.isDisable}
                                    onChange={(e) => {
                                      handleChangeContent(e);
                                    }}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 2 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.economico?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeEconomyNTema}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7  gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    id={ele.id}
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    value={ele.isTrue}
                                    onChange={handleChangeEconomyNConten}
                                    type="checkbox"
                                    disabled={ele.isDisable || false}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                    {typeSector === true || nuevoSectorial === true ? ele.contenido : ""}
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
                {/********************************************** */}
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="mb-2 rounded-md border">
                            <ModalTemasNoGRI setTemaNoGRI={setTemaNoGRI} />
                            <Disclosure.Button
                              as="div"
                              className=" btn-accordion flex justify-between px-5 flex-row-reverse  py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-10 w-10 black cursor-pointer`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </Disclosure.Button>

                            <Disclosure.Panel className="px-5 leading-7 gap-2 border-b-2 border-neutral-100">
                              <div className="max-w-4xl">
                                {TemaNoGRI.economico.length ? (
                                  <div>
                                    <div className="content-tabs">
                                      {TemaNoGRI.economico?.map(
                                        (value, index) => (
                                          <div className=" bg-white rounded-2xl px-3.5 pb-0.5 pt-2">
                                            <Disclosure key={value.topic}>
                                              <>
                                                <div className="">
                                                  <div
                                                    as="div"
                                                    className=" relative  btn-accordion flex justify-between pl-11 pr-5   py-2 text-sm font-medium text-left   rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                                                  >
                                                    <div className="flex text-start gap-2 accordion items-center">
                                                      <UpdateTemasNoGRI
                                                        TemaNoGRI={TemaNoGRI}
                                                        setTemaNoGRI={
                                                          setTemaNoGRI
                                                        }
                                                        value={value}
                                                        id={index}
                                                      />
                                                      <input
                                                        type="checkbox"
                                                        onChange={
                                                          handleChangeEconomyNTemaNoGRI
                                                        }
                                                        className="rounded border-gray-300 cursor-pointer"
                                                        name={value.topic}
                                                        value={value.topic}
                                                      />
                                                      <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                                        {value.topic}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {value.content?.map((b) => (
                                                    <div
                                                      key={b.id}
                                                      className="pl-11 flex leading-7 items-center gap-2  border-neutral-100"
                                                    >
                                                      <input
                                                        name={b.NIndicador}
                                                        checked={b.isChecked}
                                                        onChange={
                                                          handleChangeEconomyNContenNoGRI
                                                        }
                                                        type="checkbox"
                                                        className="rounded border-gray-300 cursor-pointer"
                                                      />
                                                      {b.NIndicador}
                                                    </div>
                                                  ))}
                                                </div>
                                              </>
                                            </Disclosure>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex p-5 ml-1.5">
                                    No hay nuevo Tema No GRI.
                                  </div>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 3 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.ambiental?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="  btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeAmbientalesNTema}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    onChange={handleChangeAmbientalNConten}
                                    disabled={ele.isDisable || false}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />

                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                    {typeSector === true || nuevoSectorial === true ? ele.contenido : ""}
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
                {/********************************************** */}
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="mb-2 rounded-md border">
                            <ModalTemasNoGRI setTemaNoGRI={setTemaNoGRI} />
                            <Disclosure.Button
                              as="div"
                              className="btn-accordion flex justify-between px-5 flex-row-reverse   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-10 w-10 black cursor-pointer`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-5 leading-7 gap-2 border-b-2 border-neutral-100">
                              <div className="max-w-4xl">
                                {TemaNoGRI.ambiental.length ? (
                                  <div>
                                    <div className="content-tabs">
                                      {TemaNoGRI.ambiental?.map(
                                        (value, index) => (
                                          <div className=" bg-white rounded-2xl px-3.5 pb-0.5 pt-2">
                                            <Disclosure key={value.topic}>
                                              <>
                                                <div className="">
                                                  <div
                                                    as="div"
                                                    className=" relative  btn-accordion flex justify-between pl-11 pr-5   py-2 text-sm font-medium text-left   rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                                                  >
                                                    <div className="flex text-start gap-2 accordion items-center">
                                                      <UpdateTemasNoGRI
                                                        TemaNoGRI={TemaNoGRI}
                                                        setTemaNoGRI={
                                                          setTemaNoGRI
                                                        }
                                                        value={value}
                                                        id={index}
                                                      />
                                                      <input
                                                        type="checkbox"
                                                        onChange={
                                                          handleChangeAmbientalNTemaNoGRI
                                                        }
                                                        className="rounded border-gray-300 cursor-pointer"
                                                        name={value.topic}
                                                        value={value.topic}
                                                      />
                                                      <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                                        {value.topic}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {value.content?.map((b) => (
                                                    <div
                                                      key={b.id}
                                                      className="pl-11 flex leading-7 items-center gap-2  border-neutral-100"
                                                    >
                                                      <input
                                                        name={b.NIndicador}
                                                        checked={b.isChecked}
                                                        onChange={
                                                          handleChangeAmbientalNContenNoGRI
                                                        }
                                                        type="checkbox"
                                                        className="rounded border-gray-300 cursor-pointer"
                                                      />
                                                      {b.NIndicador}
                                                    </div>
                                                  ))}
                                                </div>
                                              </>
                                            </Disclosure>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex p-5 ml-1.5">
                                    No hay nuevo Tema No GRI.
                                  </div>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 4 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.social?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeSocialNTema}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content?.map((ele, index) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    onChange={handleChangeSocialNConten}
                                    disabled={ele.isDisable || false}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                    {typeSector === true || nuevoSectorial === true ? ele.contenido : ""}
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>

                {/********************************************** */}
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="mb-2 rounded-md border">
                            <ModalTemasNoGRI setTemaNoGRI={setTemaNoGRI} />
                            <Disclosure.Button
                              as="div"
                              className="btn-accordion flex justify-between px-5 flex-row-reverse   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-10 w-10 black cursor-pointer`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-5 leading-7 gap-2 border-b-2 border-neutral-100">
                              <div className="max-w-4xl">
                                {TemaNoGRI.social.length ? (
                                  <div>
                                    <div className="content-tabs">
                                      {TemaNoGRI.social?.map((value, index) => (
                                        <div className=" bg-white rounded-2xl px-3.5 pb-0.5 pt-2">
                                          <Disclosure key={value.topic}>
                                            <>
                                              <div className="">
                                                <div
                                                  as="div"
                                                  className=" relative  btn-accordion flex justify-between pl-11 pr-5   py-2 text-sm font-medium text-left   rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                                                >
                                                  <div className="flex text-start gap-2 accordion items-center">
                                                    <UpdateTemasNoGRI
                                                      TemaNoGRI={TemaNoGRI}
                                                      setTemaNoGRI={
                                                        setTemaNoGRI
                                                      }
                                                      value={value}
                                                      id={index}
                                                    />
                                                    <input
                                                      type="checkbox"
                                                      onChange={
                                                        handleChangeSocialNTemaNoGRI
                                                      }
                                                      className="rounded border-gray-300 cursor-pointer"
                                                      name={value.topic}
                                                      value={value.topic}
                                                    />
                                                    <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                                      {value.topic}
                                                    </p>
                                                  </div>
                                                </div>
                                                {value.content?.map((b) => (
                                                  <div
                                                    key={b.id}
                                                    className="pl-11 flex leading-7 items-center gap-2  border-neutral-100"
                                                  >
                                                    <input
                                                      name={b.NIndicador}
                                                      checked={b.isChecked}
                                                      onChange={
                                                        handleChangeSocialNContenNoGRI
                                                      }
                                                      type="checkbox"
                                                      className="rounded border-gray-300 cursor-pointer"
                                                    />
                                                    {b.NIndicador}
                                                  </div>
                                                ))}
                                              </div>
                                            </>
                                          </Disclosure>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex p-5 ml-1.5">
                                    No hay nuevo Tema No GRI.
                                  </div>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        filterValue={filterValue}
        setCompany={setCompany}
        company={company}
        updateCompany={updateCompany}
      />
      <ModalSectorial
        showModalSectorial={showModalSectorial}
        setShowModalSectorial={setShowModalSectorial}
        company={company}
      />
      <Footer />
    </>
  );
};
